<template>
  <div>
    <slot />
    <a-modal
      title="Activate Tax Region"
      :visible="visible"
      @ok="onConfirm"
      okText="Activate"
      :confirmLoading="loading"
      @cancel="$emit('close')"
    >
      <p>You are about to activate the <strong>{{ region.name }}</strong> tax region. New quotes and supplies will take VAT into consideration.</p>
      <a-form :form="form" @submit.prevent.native="onConfirm">
        <a-form-item label="Supplier Country" :colon="false" required>
          <a-select
            v-decorator="['country_code', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Country is required.' }],
              }]
            }]"
            name="country_code"
            :filterOption="filterOption"
            placeholder="Select a country"
            showSearch
          >
            <a-select-option v-for="(name, code) in $store.state.countries" :key="code" :value="code">
              {{ name }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="Reporting Currency" :colon="false" required>
          <a-select
            v-decorator="['currency', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'Currency is required.' }],
              }]
            }]"
            name="currency"
            showSearch
          >
            <a-select-option v-for="currency in currencies" :key="currency" :value="currency">
              {{ currency }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item v-if="region.type !== 'ru_vat'" :label="vatIdLabel" :colon="false">
          <a-input
            v-decorator="['vat_id', {
              validate: [{
                trigger: 'change',
                rules: [{ required: true, message: 'VAT ID is required.' }],
              }]
            }]"
            name="vat_id"
            type="text"
          />
        </a-form-item>
      </a-form>
      <small>
        <p class="text-muted">Ensure that you are registered for VAT and know where to remit VAT that you collect from customers in {{ region.name }}.</p>
      </small>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    region: Object,
  },

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    currencies() { // Keep this identical with the RegionDefault component.
      switch (this.region.type) {
        case 'au_gst':
          return ['AUD']
        case 'ch_vat':
          return ['CHF']
        case 'eu_vat':
          return ['BGN', 'CZK', 'DKK', 'EUR', 'HRK', 'HUF', 'PLN', 'RON', 'SEK']
        case 'gb_vat':
          return ['GBP']
        case 'no_vat':
          return ['NOK']
        case 'ru_vat':
          return ['RUB']
      }
    },
    vatIdLabel() {
      switch (this.region.type) {
        case 'au_gst':
          return 'ABN'
        case 'ch_vat':
          return 'UID'
        case 'no_vat':
          return 'Organization Number'
        default:
          return 'VAT ID'
      }
    },
  },

  created() {
    this.form = this.$form.createForm(this, {
      mapPropsToFields: () => {
        return {
          country_code: this.$form.createFormField({ value: this.$store.state.user.address.country_code }),
          currency: this.$form.createFormField({ value: this.currencies[0] }),
        }
      },
    })
  },

  methods: {
    onConfirm() {
      this.form.validateFields(async (err, values) => {
        try {
          if (err) return false
          this.loading = true

          var { data } = await this.$httpd.post(`/user/regions`, {
            type: this.region.type,
            ...values,
          })
        } catch (err) {
          if (err.response) {
            this.$message.error(err.response.data.message)
          } else {
            this.$message.error(err.message)
          }

          return false
        } finally {
          this.loading = false
        }

        this.$store.commit('user', data)
        this.$message.success('Tax region has been activated.')
        this.$emit('success')
      })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
