<template>
  <a-form @submit.prevent.native="onSubmit">
    <div class="card">
      <div class="card-header">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'regions' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Tax Regions</router-link>
          </li>
          <li class="breadcrumb-item active">
            European Union
          </li>
        </ul>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Supplier Country</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-select
                v-model="values.country_code"
                name="country_code"
                placeholder="Select a country"
                showSearch
                :filterOption="filterOption"
                @change="getRate"
              >
                <a-select-option v-for="(name, code) in $store.state.countries" :key="code" :value="code">
                  {{ name }}
                </a-select-option>
              </a-select>
            </div>
            <small class="text-muted">
              <p>Set it to the country from which your supplies originate. This is normally the country of your business.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">VAT ID</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-input
                v-model="values.vat_id"
                name="vat_id"
                type="text"
              />
            </div>
            <small class="text-muted">
              <p>You can use a VAT ID or OSS ID that’s different from the VAT ID in your <router-link :to="{ name: 'invoices' }">billing address</router-link>. We obtain a unique consultation number from VIES with every validation request. A consultation number is an official piece of evidence to prove to your tax administration of a member state that you checked a VAT ID on a given date.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Reporting Currency</label>
          </div>
          <div class="col-12 col-lg-9">
            <a-select v-model="values.currency" name="currency" showSearch>
              <a-select-option v-for="currency in currencies" :key="currency" :value="currency">
                {{ currency }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">VIES Downtime Handling</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-radio-group
                v-model="values.downtime_handling"
                :options="[
                  { label: 'Accept VAT ID', value: 'accept_vat_id' },
                  { label: 'Reject VAT ID', value: 'reject_vat_id' },
                ]"
              />
            </div>
            <small class="text-muted">
              <p>Government services can experience temporary downtimes due to <a href="http://ec.europa.eu/taxation_customs/vies/help.html" target="_blank">regular maintenance periods <i class="fal fa-external-link mr-1"></i></a> or other reasons. To avoid disruptions when generating quotes and supplies, we utilize a fallback logic for you. Accepting a VAT ID during downtime may be recommended for lower-priced products.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">VAT OSS</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-radio-group
                v-model="values.vat_moss_opt_in"
                :options="[
                  { label: 'Opted-in', value: true },
                  { label: 'Opted-out', value: false },
                ]"
                :disabled="!isMemberState"
              />
            </div>
            <small v-if="values.vat_moss_opt_in">
              <p class="mb-3">Quotes to EU customers will calculate VAT at <strong>their</strong> local rate.</p>
            </small>
            <small v-else>
              <p class="mb-3">Quotes to EU customers will calculate VAT at <strong>your</strong> local rate.</p>
            </small>
            <div class="alert alert-dark">
              <p>Micro-businesses in the EU can opt out of the One-Stop Shop (OSS) scheme. The following conditions must apply:</p>
              <ul>
                <li>You supply cross-border telecommunications, broadcasting or electronic services (TBE) to customers residing in other EU member states.</li>
                <li>The total value (exclusive of VAT) of aforementioned supplies does not exceed 10,000 EUR in the current and in the preceding calendar year.</li>
              </ul>
              <small class="text-muted">
                <p>Opt-in is optional if your supplies are below the threshold, but you must opt-in as soon as you expect to exceed the threshold for the calendar year. Quarterly reporting and remittance through OSS will be due. Note that you are bound by this decision for two calendar years. More about the 2019 changes (page 1): <a href="https://ec.europa.eu/taxation_customs/business/vat/telecommunications-broadcasting-electronic-services/sites/mossportal/files/what_is_new_as_of_2019.pdf" target="_blank">VAT e-commerce package by the European Council <i class="fal fa-external-link mr-1"></i></a></p>
              </small>
            </div>
          </div>
        </div>
      </div>
      <div v-show="values.vat_moss_opt_in" class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Pieces of Evidence</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-radio-group
                v-model="values.evidences_count"
                :options="[
                  { label: '1 piece', value: 1 },
                  { label: '2 pieces', value: 2 },
                ]"
              />
            </div>
            <small class="text-muted">
              <p>Vatstack collects evidence about your customer’s location to determine the place of supply. 1 piece of evidence is sufficient if the total value (exclusive of VAT) of TBE services to customers residing in other EU member states does not exceed 100,000 EUR in the current and in the preceding calendar year. More about the 2019 changes (page 2): <a href="https://ec.europa.eu/taxation_customs/business/vat/telecommunications-broadcasting-electronic-services/sites/mossportal/files/what_is_new_as_of_2019.pdf" target="_blank">VAT e-commerce package by the European Council <i class="fal fa-external-link mr-1"></i></a></p>
            </small>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Quarterly Reminder</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-checkbox v-model="values.reminder">Subscribed</a-checkbox>
            </div>
            <small class="text-muted">
              <p>Receive an email reminder each quarter when VAT remittance is due.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-between align-items-center">
        <a-button type="primary" html-type="submit" :loading="loading">
          Save
        </a-button>
        <RegionDelete
          :visible="visible"
          :regionId="region.id"
          @cancel="visible = false"
          @delete="onDelete"
        >
          <a-button type="link" @click="visible = true">
            Deactivate
          </a-button>
        </RegionDelete>
      </div>
    </div>
  </a-form>
</template>

<script>
import RegionDelete from '@/components/Region/RegionDelete'

export default {
  components: {
    RegionDelete,
  },

  props: {
    region: Object,
  },

  data() {
    return {
      loading: false,
      visible: false,
      values: {
        country_code: this.region.country_code,
        currency: this.region.currency,
        downtime_handling: this.region.settings.downtime_handling,
        evidences_count: this.region.settings.evidences_count,
        reminder: this.region.reminder,
        vat_id: this.region.vat.number,
        vat_moss_opt_in: this.region.settings.vat_moss_opt_in,
      },
      rate: null,
    }
  },

  computed: {
    currencies() {
      return ['BGN', 'CZK', 'DKK', 'EUR', 'HRK', 'HUF', 'PLN', 'RON', 'SEK']
    },
    isMemberState() {
      return this.rate && this.rate.member_state
    },
  },

  async mounted() {
    await this.getRate()
  },

  methods: {
    async getRate() {
      try {
        this.loading = true

        var { data } = await this.$httpd.get('/rates', {
          params: { country_code: this.values.country_code }
        })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      if (data.rates_count) {
        var rates = data.rates.filter(item => item.member_state === true)
        this.rate = rates[0]
      } else {
        this.rate = null
        this.values.vat_moss_opt_in = true
      }
    },
    async onSubmit() {
      this.loading = true

      try {
        var { data } = await this.$httpd.put(`/user/regions/${ this.$route.params.id }`, this.values)

        this.$store.commit('user', data)

        this.$message.success('Regional settings have been updated.')
        this.$router.push({ name: 'regions' })
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }
    },
    onDelete() {
      this.$router.push({ name: 'regions' })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
