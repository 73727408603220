<template>
  <div class="card mb-3">
    <div class="card-header">
      <strong>Tax Regions</strong>
    </div>
    <div
      v-for="(region, i) in regions"
      :key="region.type"
      class="card-body py-3"
      :class="{ 'border-bottom': i < regions.length - 1  }"
    >
      <div class="row align-items-center">
        <div class="col-4">
          <img width="18" height="18" :src="require(`@/assets/images/countries/${ region.flag }.svg`)" class="mr-2" style="margin-top: -4px;">
          {{ region.name }}
        </div>
        <div class="col-4">
          <div v-if="region.userRegion && region.userRegion.vat.number">
            <a-tooltip v-if="region.userRegion.vat.valid" title="Valid" placement="left">
              <i class="fad fa-check-circle text-success mr-1"></i> {{ region.userRegion.vat.number }}
            </a-tooltip>
            <a-tooltip v-else title="Invalid" placement="left">
              <i class="fad fa-times-circle text-danger mr-1"></i> {{ region.userRegion.vat.number }}
            </a-tooltip>
          </div>
        </div>
        <div class="col-4 text-right">
          <router-link v-if="region.userRegion" :to="{ name: 'regions-id', params: { id: region.userRegion.id }}">
            Configure
          </router-link>
          <RegionActivate
            v-else
            :region="region"
            :visible="visible[region.type]"
            @close="visible[region.type] = false"
            @success="onActivate(region.type)"
          >
            <a-button type="primary" size="small" @click="visible[region.type] = true">
              Activate
            </a-button>
          </RegionActivate>
        </div>
      </div>
    </div>
    <div class="card-footer text-muted">
      <small>
        <p class="mb-0"><i class="fad fa-info-circle mr-1"></i> VAT reports are available immediately for activated tax regions. Write to <a href="mailto:support@vatstack.com">support@vatstack.com</a> if you don’t see a region you need supported.</p>
      </small>
    </div>
  </div>
</template>

<script>
import RegionActivate from '@/components/Region/RegionActivate'

export default {
  components: {
    RegionActivate,
  },

  data() {
    return {
      visible: {
        'au_gst': false,
        'ch_vat': false,
        'eu_vat': false,
        'gb_vat': false,
        'no_vat': false,
        'ru_vat': false,
      },
    }
  },

  computed: {
    regions() {
      return [{
        flag: 'AU',
        name: 'Australia',
        type: 'au_gst',
        userRegion: this.$store.state.user.regions.find(item => item.type === 'au_gst'),
      }, {
        flag: 'EU',
        name: 'European Union',
        type: 'eu_vat',
        userRegion: this.$store.state.user.regions.find(item => item.type === 'eu_vat'),
      }, {
        flag: 'NO',
        name: 'Norway',
        type: 'no_vat',
        userRegion: this.$store.state.user.regions.find(item => item.type === 'no_vat'),
      }, {
        flag: 'RU',
        name: 'Russia',
        type: 'ru_vat',
        userRegion: this.$store.state.user.regions.find(item => item.type === 'ru_vat'),
      }, {
        flag: 'CH',
        name: 'Switzerland',
        type: 'ch_vat',
        userRegion: this.$store.state.user.regions.find(item => item.type === 'ch_vat'),
      }, {
        flag: 'GB',
        name: 'United Kingdom',
        type: 'gb_vat',
        userRegion: this.$store.state.user.regions.find(item => item.type === 'gb_vat'),
      }]
    },
  },

  methods: {
    onActivate(type) {
      this.visible[type] = true
      this.$router.push({ name: 'regions-id', params: { id: this.$store.state.user.regions[this.$store.state.user.regions.length - 1].id }})
    },
  },
}
</script>
