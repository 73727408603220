var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._t("default"),_c('a-modal',{attrs:{"title":"Activate Tax Region","visible":_vm.visible,"okText":"Activate","confirmLoading":_vm.loading},on:{"ok":_vm.onConfirm,"cancel":function($event){return _vm.$emit('close')}}},[_c('p',[_vm._v("You are about to activate the "),_c('strong',[_vm._v(_vm._s(_vm.region.name))]),_vm._v(" tax region. New quotes and supplies will take VAT into consideration.")]),_c('a-form',{attrs:{"form":_vm.form},nativeOn:{"submit":function($event){$event.preventDefault();return _vm.onConfirm.apply(null, arguments)}}},[_c('a-form-item',{attrs:{"label":"Supplier Country","colon":false,"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['country_code', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Country is required.' }],
            }]
          }]),expression:"['country_code', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Country is required.' }],\n            }]\n          }]"}],attrs:{"name":"country_code","filterOption":_vm.filterOption,"placeholder":"Select a country","showSearch":""}},_vm._l((_vm.$store.state.countries),function(name,code){return _c('a-select-option',{key:code,attrs:{"value":code}},[_vm._v(" "+_vm._s(name)+" ")])}),1)],1),_c('a-form-item',{attrs:{"label":"Reporting Currency","colon":false,"required":""}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['currency', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'Currency is required.' }],
            }]
          }]),expression:"['currency', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'Currency is required.' }],\n            }]\n          }]"}],attrs:{"name":"currency","showSearch":""}},_vm._l((_vm.currencies),function(currency){return _c('a-select-option',{key:currency,attrs:{"value":currency}},[_vm._v(" "+_vm._s(currency)+" ")])}),1)],1),(_vm.region.type !== 'ru_vat')?_c('a-form-item',{attrs:{"label":_vm.vatIdLabel,"colon":false}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['vat_id', {
            validate: [{
              trigger: 'change',
              rules: [{ required: true, message: 'VAT ID is required.' }],
            }]
          }]),expression:"['vat_id', {\n            validate: [{\n              trigger: 'change',\n              rules: [{ required: true, message: 'VAT ID is required.' }],\n            }]\n          }]"}],attrs:{"name":"vat_id","type":"text"}})],1):_vm._e()],1),_c('small',[_c('p',{staticClass:"text-muted"},[_vm._v("Ensure that you are registered for VAT and know where to remit VAT that you collect from customers in "+_vm._s(_vm.region.name)+".")])])],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }