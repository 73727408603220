<template>
  <a-form @submit.prevent.native="onSubmit">
    <div class="card">
      <div class="card-header">
        <ul class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'regions' }"><i class="fal fa-lg fa-chevron-left mr-2"></i> Tax Regions</router-link>
          </li>
          <li class="breadcrumb-item active">
            {{ regionName }}
          </li>
        </ul>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Supplier Country</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-2">
              <a-select
                v-model="values.country_code"
                name="country_code"
                placeholder="Select a country"
                showSearch
                :filterOption="filterOption"
              >
                <a-select-option v-for="(name, code) in $store.state.countries" :key="code" :value="code">
                  {{ name }}
                </a-select-option>
              </a-select>
            </div>
            <small class="text-muted">
              <p>Set it to the country from which your supplies originate. This is normally the country of your business.</p>
            </small>
          </div>
        </div>
      </div>
      <div v-if="region.type !== 'ru_vat'" class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">{{ vatIdLabel }}</label>
          </div>
          <div class="col-12 col-lg-9">
            <a-form-item>
              <a-input
                v-model="values.vat_id"
                name="vat_id"
                type="text"
              />
            </a-form-item>
          </div>
        </div>
      </div>
      <div class="card-body border-bottom">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Reporting Currency</label>
          </div>
          <div class="col-12 col-lg-9">
            <a-select v-model="values.currency" name="currency" showSearch>
              <a-select-option v-for="currency in currencies" :key="currency" :value="currency">
                {{ currency }}
              </a-select-option>
            </a-select>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-lg-3">
            <label class="label">Quarterly Reminder</label>
          </div>
          <div class="col-12 col-lg-9">
            <div class="mb-3">
              <a-checkbox v-model="values.reminder">Subscribed</a-checkbox>
            </div>
            <small class="text-muted">
              <p>Receive an email reminder each quarter when VAT remittance is due.</p>
            </small>
          </div>
        </div>
      </div>
      <div class="card-footer d-flex justify-content-between align-items-center">
        <a-button type="primary" html-type="submit" :loading="loading">
          Save
        </a-button>
        <RegionDelete
          :visible="visible"
          :regionId="region.id"
          @cancel="visible = false"
          @delete="onDelete"
        >
          <a-button type="link" @click="visible = true">
            Deactivate
          </a-button>
        </RegionDelete>
      </div>
    </div>
  </a-form>
</template>

<script>
import RegionDelete from '@/components/Region/RegionDelete'

export default {
  components: {
    RegionDelete,
  },

  props: {
    region: Object,
    regionName: String,
  },

  data() {
    return {
      loading: false,
      visible: false,
      values: {
        country_code: this.region.country_code,
        currency: this.region.currency,
        reminder: this.region.reminder,
        vat_id: this.region.vat.number,
      },
    }
  },

  computed: {
    currencies() { // Keep this identical with the RegionAction component.
      switch (this.region.type) {
        case 'au_gst':
          return ['AUD']
        case 'ch_vat':
          return ['CHF']
        case 'eu_vat':
          return ['BGN', 'CZK', 'DKK', 'EUR', 'HRK', 'HUF', 'PLN', 'RON', 'SEK']
        case 'gb_vat':
          return ['GBP']
        case 'no_vat':
          return ['NOK']
        case 'ru_vat':
          return ['RUB']
      }
    },
    vatIdLabel() {
      switch (this.region.type) {
        case 'au_gst':
          return 'ABN'
        case 'ch_vat':
          return 'UID'
        case 'no_vat':
          return 'Organization Number'
        default:
          return 'VAT ID'
      }
    },
  },

  methods: {
    async onSubmit() {
      this.loading = true

      try {
        var { data } = await this.$httpd.put(`/user/regions/${ this.$route.params.id }`, this.values)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.$message.success('Regional settings have been updated.')
      this.$store.commit('user', data)
      this.$router.push({ name: 'regions' })
    },
    onDelete() {
      this.$router.push({ name: 'regions' })
    },
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
  },
}
</script>
