<template>
  <div>
    <RegionEuropeanUnion
      v-if="region.type === 'eu_vat'"
      :region="region"
    />
    <RegionDefault
      v-else
      :region="region"
      :regionName="regions[region.type]"
    />
  </div>
</template>

<script>
import RegionDefault from '@/components/Region/RegionDefault'
import RegionEuropeanUnion from '@/components/Region/RegionEuropeanUnion'

export default {
  components: {
    RegionDefault,
    RegionEuropeanUnion,
  },

  data() {
    return {
      regions: {
        'au_gst': 'Australia',
        'ch_vat': 'Switzerland',
        'eu_vat': 'European Union',
        'gb_vat': 'United Kingdom',
        'no_vat': 'Norway',
        'ru_vat': 'Russia',
      },
    }
  },

  computed: {
    region() {
      return this.$store.state.user.regions.find(item => item.id === this.$route.params.id)
    },
  },
}
</script>
