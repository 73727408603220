<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    regionId: String,
  },

  data() {
    return {
      loading: false,
    }
  },

  methods: {
    onDelete() {
      this.$confirm({
        title: 'Confirm Deactivation',
        content: 'This will deactivate the tax region and delete its settings. You can add it back, but you need to restart the configuration. Continue with the deactivation?',
        okText: 'Deactivate',
        okButtonProps: {
          loading: this.loading,
        },
        onOk: async () => {
          await this.onSubmit()
        },
        onCancel: () => {
          this.$emit('cancel')
        },
      })
    },
    async onSubmit() {
      try {
        this.loading = true

        var { data } = await this.$httpd.delete(`/user/regions/${ this.regionId }`)
      } catch (err) {
        if (err.response) {
          this.$message.error(err.response.data.message)
        } else {
          this.$message.error(err.message)
        }

        return false
      } finally {
        this.loading = false
      }

      this.$store.commit('user', data)
      this.$message.success('Tax region has been deactivated.')

      this.$emit('delete')
    },
  },

  watch: {
    visible(value) {
      if (value) {
        this.onDelete()
      }
    },
  },
}
</script>
